import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Headings } from "../../components";
import { data } from "../../constants";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";

import "./Services.css";

const Services = () => {
  const [shown, setShown] = useState(-1);

  const closeModal = () => {
    setShown(-1);
  };

  return (
    <div id="services" className="d-block pt-md-4">
      <Headings
        title="Services"
        text="At our digital marketing agency, we offer a range of services to help businesses grow and succeed online. These services include"
      />

      <Accordion>
        {data.ServicesData.map((item, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              <div>
                <span>{index + 1}</span> {item.title}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                {item.services.map(
                  (
                    {
                      titleone,
                      titletwo,
                      link,
                      itemclass,
                      imgURL,
                      description,
                    },
                    index
                  ) => (
                    <div className="col-lg-4 col-12" key={index}>
                      <div className={`row ${itemclass}`}>
                        <div className="col-md-6 box">
                          <div>
                            {" "}
                            <span>{titleone} </span>
                            <span>{titletwo}</span>
                          </div>

                          <a
                            // href={"#"}
                            alt={titleone}
                            className="readmore"
                            onClick={() => setShown(index)}
                          >
                            {" "}
                            <BsFillArrowUpRightCircleFill /> Learn more{" "}
                          </a>
                          {/* <a>
                            {" "}
                            <BsFillArrowUpRightCircleFill /> Learn More
                          </a> */}
                        </div>
                        {/* <div className="col-md-6 text-end">
                          <img
                            src={imgURL}
                            alt={titleone}
                            className="img-fluid img-services"
                          />
                        </div> */}
                      </div>
                    </div>
                  )
                )}
                {shown >= 0 ? (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      zIndex: 10000,
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "300px",
                        padding: "20px",
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                        position: "relative",
                      }}
                    >
                      <button
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          cursor: "pointer",
                          padding: "4px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                        onClick={closeModal}
                      >
                        X
                      </button>
                      <div className="description">
                        {item?.services[shown]?.description}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default Services;
