import images from './images';

const Menu = [
    {
        text: 'About us',
        link: '#team',
    },
    {
        text: 'Services',
        link: '#services',
    },
    // {
    //     text: 'Use Cases',
    //     link: '#use-cases',
    // },
    // {
    //     text: 'Process',
    //     link: '#process',
    // },

    // {
    //     text: 'Testimonials',
    //     link: '#testimonials',
    // },
    {
        text: 'Request a quote',
        link: '#quote',
    }
];

const ServicesData = [
    {
        title: 'Digital Marketing Services',
        services: [
            {
                titleone: 'Digital Strategy',
                titletwo: 'Consultation',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "The first step to creating a digital presence is developing an effective strategy. Our team works closely with you to determine your business objectives, target audience and the competitive landscape in which they operate. From this point, we create a customized digital strategy that makes your brand stand out in the competitive online environment.",
            },
            {
                titleone: 'Search Engine',
                titletwo: 'Optimization',
                link: '#',
                itemclass: 'bgreen',
                imgURL: images.services02,
                description: "Fly high with our SEO expertise in search engine rankings. We crack the codes of algorithms, seeding keywords where they matter most and moulding your online brand to stand out not just as seen but remembered. We optimize your website, generate engaging content and use effective strategies to increase the organic traffic on your site as well as improve your online reputation."
            },
            {
                titleone: 'Social',
                titletwo: 'Orchestration',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services03,
                description: "Interact with and reach your audience through social media channels. Our social media management services include content creation, audience targeting and analytics to optimize the impact of your brand on Facebook, Instagram, Twitter, Pinterest and LinkedIn platforms. Reflect on your brand in the orchestration of social media. We orchestrate engagement in the social space where conversations are set ablaze and trends catch fire."
            },
            {
                titleone: 'Content',
                titletwo: 'Galaxy',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services04,
                description: "Successful digital strategies are all about content. Enter our Content Universe, where words meet images and stories to form a cosmic harmony. Use blogs, videos and visuals that catch the eye of digital space to generate storytelling power."
            },
            {
                titleone: 'Paid',
                titletwo: 'Advertising',
                link: '#',
                itemclass: 'bgreen',
                imgURL: images.services05,
                description: "Use highly targeted paid advertising campaigns to drive high-quality traffic directly to your site. Our PPC specialists oversee every aspect of your campaigns, leading to high performance and a healthy ROI."
            },
            {
                titleone: 'Email',
                titletwo: 'Marketing',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Nurture leads, develop relationships, and encourage conversions via personalized email campaigns. Our email marketing services are created to attract your audience and keep our brand in mind."
            },
            {
                titleone: 'Analytics',
                titletwo: 'Reporting',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Assess the effectiveness of your digital initiatives with our advanced analytics and reporting solutions. We offer measurable insights, monitor relevant metrics and continually enhance your strategy for the best results."
            },
            {
                titleone: 'Affiliate',
                titletwo: 'Marketing',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Team up with the affiliates that are giving the best results to increase your online visibility by getting paid commissions. Our exquisite affiliate program management will coordinate our collaborative efforts and create a wider variety of options to enhance your branding as well as revenue."
            },
            {
                titleone: 'Influencer',
                titletwo: 'Marketing',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Strengthen your brand by collaborating with reputable partners. Highlight the top influencers in your area of specialization. Also, run the outreach campaign and reach out to partners that your audience will relate to. Influencer marketing has a distinct ability to create genuine connections and fuel loyalty to the brand, therefore, use this strategy to your advantage."
            },
            {
                titleone: 'Online Public',
                titletwo: 'Relations',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Protect and enhance your online brand reputation with our public relations helping hand. Our area of focus is on the handling of online reviews, the development of crisis management strategies, and the creation of a positive online story that resonates with your intended audience."
            },
            {
                titleone: 'Video',
                titletwo: 'Marketing',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Grab the viewers’ attention with compelling video material. As YouTube experts, we offer YouTube marketing, live-streaming, and video ads services, aiding you in creating and promoting videos that make an impact on your audience, putting your brand in a spotlight and engaging your audience."
            },
            {
                titleone: 'Web Analytics',
                titletwo: 'Conversion Rate Optimization',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "The conversion of your website can be improved by using A/B testing, the analysis of the user experience, the optimization of funnels, and goal tracking. Our CRO plans help us to crank up engagement, boost conversions, and to keep on top of online growth."
            },
            {
                titleone: 'Customer Relationship',
                titletwo: 'Management',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "CRM strategies can be personalized to meet individual customer interaction needs. The service offered includes customer segmentation, hassle-free email integration, data analysis, and personalization which allows the business to personalize customer engagement."
            },
            {
                titleone: 'Chatbots',
                titletwo: 'Artificial Intelligence',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Revolutionize customer interaction through the use of chatbots that are AI-enabled. AI-enabled marketing strategies allow personalization of customer experience that leads to an increase in engagement and ease of communication by the customers."
            },
            {
                titleone: 'E-commerce',
                titletwo: 'Marketing',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Let our cutting-edge e-commerce marketing techniques raise your online sales. We do optimization of product listings, ensure shopping ads are aimed at effectiveness and run retargeting campaigns to drive conversions and leverage the e-commerce potential."
            },
            {
                titleone: 'Digital',
                titletwo: 'Branding',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Branding yourself digitally? We can help. Our experts create branding strategies, develop a powerful online presence, and maintain your brand reputation to guarantee that your brand is different in the digital environment."
            },
            {
                titleone: 'Podcast',
                titletwo: 'Marketing',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Leverage podcasts to stretch your audience out. Create sponsorship packages, secure partnerships and invite smooth audience interaction by using our marketing strategies, which are perfectly fitted for your brand."
            },
            {
                titleone: 'Webinars',
                titletwo: 'Virtual Events',
                link: '#',
                itemclass: 'bdark',
                imgURL: images.services06,
                description: "Keep in touch with your followers using online events. We offer webinars hosting, webinars marketing strategies and virtual conferences, and therefore, you will never be left behind in the digital space."
            }
        ]
    },
    {
        title: 'Content Creation',
        services: [
            {
                titleone: 'Content Strategy',
                titletwo: 'Development',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Let our tailored content strategies turn your digital territory into a thriving place. Incorporated with your business objectives, our roadmaps include content creation, distribution, and optimization, hence creating a holistic approach to your online presence."
            },
            {
                titleone: 'Blog Content',
                titletwo: 'Creation',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Catch the attention of your readers through well-written and SEO- friendly blog posts. We are at the top of producing posts that not only are relevant to the user’s question but also make you an industry leader, thus driving traffic and engagement."
            },
            {
                titleone: 'Website Content',
                titletwo: 'Development',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Our website content services will power up your online presence through captivating website content. Through grabbing landing pages and smart product descriptions, we tell a digital story that connects with your audience and highlights your brand."
            },
            {
                titleone: 'Social Media',
                titletwo: 'Content',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Utilize the power of social media to create visually appealing and shareable content. Our high-quality graphics, images, and videos not only seize attention but also create a meaningful bond between your brand and your target audience."
            },
            {
                titleone: 'Email Marketing',
                titletwo: 'Content',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Experience a paradigm shift in your email marketing by harnessing our powerful content. From amazing subject lines to appealing copies and irresistible calls to action, we guarantee that your emails will not only end up in the inboxes but also lead to conversions."
            },
            {
                titleone: 'Infographic',
                titletwo: 'Creation',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Turn complex information into visual-friendly infographics. Our graphics and charts translate data into visually appealing images that can fit your blogs, social media, and presentations."
            },
            {
                titleone: 'Video Content',
                titletwo: 'Production',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Show the potential of our video creation services to your audience. From scripting to filming and editing, we create engaging promotional videos, how-tos , and demo videos that are made to support your online presence."
            },
            {
                titleone: 'E-book and Whitepaper',
                titletwo: 'Writing',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Place yourself as an industry knowledge leader by reading our in-depth e-books and whitepapers. We excel in researching and presenting high- quality information in a form that is engaging to your audience."
            },
            {
                titleone: 'Case',
                titletwo: 'Studies',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Highlight your successes in an account of intriguing case studies. By emphasizing on your expertise and showing how your brand improves and builds customer trust, we write stories that will appeal to your audience."
            },
            {
                titleone: 'Content Calendar',
                titletwo: 'Management',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Smartly handle the content with our content calendars that are well managed. We make sure that you can reach your audience on all platforms and maintain consistency. We also make sure that your message is well-timed and exciting."
            },
            {
                titleone: 'Podcast Scripting',
                titletwo: 'Editing',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Your brand voice is what sets you apart. Our podcast services will help you elevate it. We deliver it all, from writing the engaging content to the perfect editing, so that the final product will appeal your audience and improve your online presence."
            },
            {
                titleone: 'Interactive Content',
                titletwo: 'Development',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Our interactive content will be a great way to engage users and keep them coming back. We aren’t only giving quizzes and polls to your audience, we also create compelling experiences that will be loved and will also provide you with valuable insights."
            },
            {
                titleone: 'User-Generated',
                titletwo: 'Content Campaigns',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Make your audience your strength with our user-generated content programmes. Encourage customers to post positive reviews and testimonials which will create and authentic brand story."
            },
            {
                titleone: 'SEO Content',
                titletwo: 'Optimization',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Increase your online presence with our SEO-friendly content optimization. From optimizing current content to the creation of new search engine-optimized content, we elevate your brand position right at the top of organic search results, leading to an increase in organic traffic and growth."
            }
        ]
    },
    {
        title: 'Our Administration Services',
        services: [
            {
                titleone: 'Efficient Workflow',
                titletwo: 'Design',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "We see the value of well-defined processes. Our team builds up workflows that are relevant to your business goals. These processes are tailored to improve efficiency, which will give you transparency and smooth flow in your daily activities."
            },
            {
                titleone: 'Human Resources',
                titletwo: 'Management',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Maximize your workforce efficiency with our HR management solutions. From recruitment and onboarding to performance management and employee development, we offer full-scale HR support to grow your best asset – your team."
            },
            {
                titleone: 'Financial Management',
                titletwo: 'Budgeting',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Get your finances under control with our professional financial management and budgeting services. Our team assists you in setting up a strong financial process, keeping track of your expenses, and building strategic budgets that guarantee financial stability and growth."
            },
            {
                titleone: 'Legal Compliance',
                titletwo: 'Risk Management',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Ensure compliance with the ever-changing regulations and manage risks efficiently with our legal compliance and risk management services. We perform detailed audits, pinpoint possible risks, and put in place preventive measures to protect your business."
            },
            {
                titleone: 'Office Administration',
                titletwo: 'Support',
                link: '#',
                itemclass: 'blight',
                imgURL: images.services01,
                description: "Simplify your daily business routines with our office administration support services. From correspondence and appointment scheduling to meeting coordination and logistics, we ensure your office is well-organized."
            }
        ]
    }
];

const CaseStudies = [
    {
        text: 'For a local restaurant, we implemented a targeted PPC campaign that resulted in a 50% increase in website traffic and a 25% increase in sales.',
        link: '#',
    },
    {
        text: 'For a B2B software company, we developed an SEO strategy that resulted in a first page ranking for key keywords and a 200% increase in organic traffic.',
        link: '#',
    },
    {
        text: 'For a national retail chain, we created a social media marketing campaign that increased followers by 25% and generated a 20% increase in online sales.',
        link: '#',
    },
];

const WorkingProcess = [
    {
        title: 'Initial Consultation',
        description: 'First, we will determine what your business is all about and its needs through an extensive consultation session.',
    },
    {
        title: 'Customized Strategy Development',
        description: 'In addition to developing a customized plan combining digital marketing, administration and content creation, our specialists will strive to achieve your goals.',
    },
    {
        title: 'Execution and Implementation',
        description: 'We go beyond the strategy by employing current methods and tools to achieve results at every channel of our business.',
    },
    {
        title: 'Continuous Monitoring and Optimization',
        description: 'We follow up on campaign results, data analysis, and apply in-case changes to achieve maximum output and ROI.',
    },
    {
        title: 'Regular Reporting and Communication',
        description: 'This means that reporting and communication must be done transparently to keep you updated on progress, problems and/or new opportunities, thus allowing joint responsibility for lasting success.',
    }
];

// const Team = [
//     {
//         name: 'John Smith',
//         position: 'CEO and Founder',
//         info: '10+ years of experience in digital marketing. Expertise in SEO, PPC, and content strategy',
//         foto: images.team01,
//         linkedin: '#',
//     },
//     {
//         name: 'Jane Doe',
//         position: 'Director of Operations',
//         info: '7+ years of experience in project management and team leadership. Strong organizational and communication skills',
//         foto: images.team02,
//         linkedin: '#',
//     },
//     {
//         name: 'Michael Brown',
//         position: 'Senior SEO Specialist',
//         info: '5+ years of experience in SEO and content creation. Proficient in keyword research and on-page optimization',
//         foto: images.team03,
//         linkedin: '#',
//     },
//     {
//         name: 'Emily Johnson',
//         position: 'PPC Manager',
//         info: '3+ years of experience in paid search advertising. Skilled in campaign management and performance analysis',
//         foto: images.team04,
//         linkedin: '#',
//     },
//     {
//         name: 'Brian Williams',
//         position: 'Social Media Specialist',
//         info: '4+ years of experience in social media marketing. Proficient in creating and scheduling content, analyzing metrics, and building engagement',
//         foto: images.team05,
//         linkedin: '#',
//     },
//     {
//         name: 'Sarah Kim',
//         position: 'Content Creator',
//         info: '2+ years of experience in writing and editing Skilled in creating compelling, SEO-optimized content for various industries',
//         foto: images.team06,
//         linkedin: '#',
//     },

// ];

// const Testimonials = [
//     {
//         name: 'John Smith',
//         position: 'Marketing Director at XYZ Corp',
//         testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
//     },
//     {
//         name: 'John Smith',
//         position: 'Marketing Director at XYZ Corp',
//         testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
//     },
//     {
//         name: 'John Smith',
//         position: 'Marketing Director at XYZ Corp',
//         testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
//     },
//     {
//         name: 'John Smith',
//         position: 'Marketing Director at XYZ Corp',
//         testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
//     },
// ];
export default { Menu, CaseStudies, WorkingProcess, ServicesData };