import React, { useState } from "react";
import { Headings } from "../../components";
import axios from "axios"; // Import axios

import "./Contact.css";

const Contact = () => {
  function Submit(e) {
    e.preventDefault();

    const formEle = e.target; // Use e.target to get the form element
    const formDatab = new FormData(formEle);
    formDatab.append("Time", new Date());
    fetch(
      "https://script.google.com/macros/s/AKfycbxTB2DUylvvVeEaC-aPGKqW8BXPMx2-Az2CPKHsaypeN5a3waRe1U5xcNMIluRDaJ03_Q/exec",
      {
        redirect: "follow",
        method: "POST",
        body: formDatab,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
    alert(" Request Submitted !");
  }

  return (
    <div className="section-padding">
      <Headings
        title="Contact Us"
        text="Connect with Us: Let's  Discuss Your Digital Marketing Needs"
      />

      <div className="contact" id="quote">
        <div className="row">
          <div className="col-md-6 col-12">
            <form onSubmit={(e) => Submit(e)}>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Type"
                  id="inlineRadio1"
                  value="Hi"
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Say Hi
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Type"
                  id="inlineRadio2"
                  value="Quote"
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Get a Quote
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="name">Name*</label>
                <input
                  className="form-control"
                  type="text"
                  name="Name"
                  id="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input
                  className="form-control"
                  type="email"
                  name="Email"
                  id="email"
                  placeholder="Email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Message*</label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  name="Message"
                  rows="3"
                ></textarea>
              </div>
              <button type="submit" className="btn-positivus w-100">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
