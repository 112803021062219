import React from "react";
import { images } from "../../constants";
import { IconScroll } from "../../components";
import "./Hero.css";

const logos = ["logo01", "logo02", "logo03", "logo04", "logo05", "logo06"];

const Hero = () => {
  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title">
            {" "}
            "Navigating success, digitally and beyond"{" "}
          </h1>
          <p className="py-4">
            Welcome to CreativeSphere Solutions, a single source that caters for
            all your digital needs. By providing you with Digital Marketing,
            Content Creation, and Administration Management services, we make
            your online presence outstanding and smooth the process. Our team of
            experts will guarantee that the SEO-driven strategy is in place for
            improved visibility and help write content that will give your brand
            identity a boost. Trust CreativeSphere Solutions as your digital
            buddy, where inventiveness and efficiency merge hand in hand,
            propelling your business to new heights.
          </p>
          <a href={"#quote"} alt="Book a consultation">
            <button className="btn-positivus">Book a consultation</button>
          </a>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.hero} alt="design" />
        </div>
      </div>

      {/* <div className="clients">
        {logos.map((logo, index) => (
          <img key={index} src={images[logo]} alt={images[logo]} />
        ))}
      </div> */}
      {/* <IconScroll /> */}
    </div>
  );
};

export default Hero;
